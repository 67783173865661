import { useColorMode, useColorModeValue, useTheme } from '@chakra-ui/react';
import { Loading } from '../../..';
import Select, { Props as SelectProps } from 'react-select';
import { useChakraThemeParser } from './useThemeParser';
import { ReactSelectOption } from './utils';

interface Props extends SelectProps<ReactSelectOption> {
  isInvalid?: boolean;
}

export const Selector: React.FC<Props> = (props) => {
  const theme = useChakraThemeParser();

  const zIndex = useZIndex();
  const errorColor = useErrorColor();

  const menuColor = useMenuColor();
  const disabledColor = useDisabledColor();
  const isSearchable = props.isSearchable ? props.isSearchable : false;

  return (
    <Select
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex,
          backgroundColor: menuColor,
          cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        }),
        control: (provided) => {
          if (props.isDisabled) {
            return {
              ...provided,
              borderColor: disabledColor,
              cursor: props.isDisabled ? 'not-allowed' : 'pointer',
            };
          }
          return {
            ...provided,
            borderWidth: props?.isInvalid ? 1 : 0,
            borderColor: errorColor,
            borderStyle: 'solid',
          };
        },
        singleValue: (provided) => ({
          ...provided,
          padding: '0 5px',
          borderRadius: 10,
          cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        }),
        multiValue: (provided) => ({
          ...provided,
          padding: '0 5px',
          borderRadius: 10,
          cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        }),
        input: (provided) => ({
          ...provided,
          padding: '0 5px',
          borderRadius: 10,
          cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        }),
        placeholder: (provided) => ({
          ...provided,
          margin: '0 10px',
          cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        }),
        option: (provided) => ({
          ...provided,
          padding: '10px 20px',
          borderRadius: '5px',
          cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        }),
        indicatorSeparator: (provided) => {
          if (props.isDisabled) {
            return {
              ...provided,
              backgroundColor: disabledColor,
            };
          }
          return {
            ...provided,
          };
        },
      }}
      components={{
        LoadingIndicator: () => <Loading mr={2} size="sm" />,
      }}
      theme={theme}
      value={props.value}
      defaultValue={props.defaultValue}
      isMulti={props.isMulti}
      options={props.options}
      onChange={props.onChange}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable={isSearchable}
      isLoading={props.isLoading}
      placeholder={props.placeholder}
      blurInputOnSelect={props.blurInputOnSelect}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onKeyDown={props.onKeyDown}
      inputId={props.id}
    />
  );
};

const useZIndex = (): number => {
  const theme = useTheme();

  if (theme.zIndices && 'overlay' in theme.zIndices) {
    // eslint-disable-next-line
    return theme.zIndices.overlay as number;
  }

  return 1;
};

const useErrorColor = (): string => {
  const theme = useTheme();

  // eslint-disable-next-line
  return useColorModeValue(theme.colors.red[500], theme.colors.red[400]) as string;
};

const useMenuColor = (): string => {
  const theme = useTheme();
  const { colorMode } = useColorMode();

  if ('semanticTokens' in theme && 'colors' in theme.semanticTokens) {
    let bgColor = '';
    // eslint-disable-next-line
    colorMode === 'light' ? (bgColor = theme.colors.light[900] as string) : (bgColor = theme.colors.dark[100] as string);

    return bgColor;
  }

  return 'grey';
};

const useDisabledColor = (): string => {
  const theme = useTheme();
  const { colorMode } = useColorMode();
  if ('semanticTokens' in theme && 'colors' in theme.semanticTokens) {
    let bgColor = '';
    // eslint-disable-next-line
    colorMode === 'light' ? (bgColor = theme.colors.light[300] as string) : (bgColor = theme.colors.dark[300] as string);
    return bgColor;
  }
  return 'grey';
};
