import { Box, BoxProps } from '@chakra-ui/react';
import { Selector } from './ReactSelect/Selector';
import { ReactSelectOption, SelectorOnChange, SelectorProps, isMultiSelector } from './ReactSelect/utils';

import { API, APIOutputs } from '../../../api/trpc';
import { APIError } from '../../Error/ErrorViewer';
import { defineMessage, useIntl } from 'react-intl';
import React from 'react';

type Props = SelectorProps & {
  containerProps?: BoxProps;
  autoSelectLatest?: boolean;
};

const placeholder = defineMessage({
  id: 'WhatsNew.version_placeholder',
  defaultMessage: 'Version',
});

type ListVersionOutput = APIOutputs['changeLogs']['listVersion'];
export const ChangeLogVersionSelector: React.FC<Props> = (props) => {
  const { containerProps, autoSelectLatest, ...selectorProps } = props;

  const intl = useIntl();

  const onSuccess = (versions: ListVersionOutput) => {
    if (autoSelectLatest && versions.length > 0) {
      const autoSelectedVersion = versions[0].version;

      if (isMultiSelector(selectorProps)) {
        selectorProps.onChange([autoSelectedVersion]);
      } else {
        selectorProps.onChange(autoSelectedVersion);
      }
    }
  };
  const { data, error, isLoading } = API.changeLogs.listVersion.useQuery(undefined, { onSuccess });

  const formatOption = (id: string | number): ReactSelectOption => {
    const changelog = data?.find((changelog) => changelog.version === Number(id));

    if (!changelog) {
      return null;
    }

    return {
      value: id,
      label: `v${changelog.version}`,
    };
  };

  const options = data?.map((changelog) => formatOption(changelog.version));

  const onChange = (value: SelectorOnChange) => {
    if (isMultiSelector(selectorProps)) {
      const newValue = value as Array<ReactSelectOption> | null;
      const selectedValues = (newValue ?? []).map((option) => option?.value ?? '');

      selectorProps.onChange(selectedValues);
    } else {
      const newValue = value as ReactSelectOption | null;
      const selectedValue = newValue?.value ?? '';

      selectorProps.onChange(selectedValue);
    }
  };

  const defaultValue = selectorProps?.defaultValue
    ? isMultiSelector(selectorProps)
      ? selectorProps.defaultValue.map(formatOption)
      : formatOption(selectorProps.defaultValue)
    : undefined;

  const value = selectorProps?.value
    ? isMultiSelector(selectorProps)
      ? selectorProps.value.map(formatOption)
      : formatOption(selectorProps.value)
    : undefined;

  return (
    <Box {...containerProps}>
      <Selector
        {...selectorProps}
        options={options}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        placeholder={intl.formatMessage(placeholder)}
        isLoading={isLoading}
      />
      {error && <APIError error={error} />}
    </Box>
  );
};
