import React from 'react';
import { ScaleFade, SlideFade } from '@chakra-ui/react';
import { useNavigationType } from 'react-router-dom';

interface AnimatedSwipeWrapperProps {
  offsetX?: string | number;
  offsetY?: string | number;

  children: React.ReactNode;
}

export const RightToLeftAnimation: React.FC<AnimatedSwipeWrapperProps> = (props) => {
  const { offsetX = '24ch', offsetY = 0 } = props;

  return (
    <SlideFade offsetX={offsetX} offsetY={offsetY} in={true} transition={{ enter: { duration: 0.3 } }}>
      {props.children}
    </SlideFade>
  );
};

export const LeftToRightAnimation: React.FC<AnimatedSwipeWrapperProps> = (props) => {
  const { offsetX = '24ch', offsetY = 0 } = props;

  return (
    <SlideFade offsetX={`-${offsetX}`} offsetY={offsetY} in={true} transition={{ enter: { duration: 0.3 } }}>
      {props.children}
    </SlideFade>
  );
};

export const BottomToTopAnimation: React.FC<AnimatedSwipeWrapperProps> = (props) => {
  const { offsetX = 0, offsetY = '24ch' } = props;

  return (
    <SlideFade offsetX={offsetX} offsetY={offsetY} in={true}>
      {props.children}
    </SlideFade>
  );
};

interface AnimatedScaleWrapperProps {
  initialScale?: number;
  children: React.ReactNode;
}

export const ScaleAnimation: React.FC<AnimatedScaleWrapperProps> = (props) => {
  const { initialScale = 0.85 } = props;

  return (
    <ScaleFade initialScale={initialScale} in={true} transition={{ enter: { duration: 0.3 } }}>
      {props.children}
    </ScaleFade>
  );
};

/**
 * This Animation utilizes the useNavigationType hook to determine the type of navigation action
 * that triggered the current render. This information is used to adjust the animation direction dynamically.
 * Specifically, when the navigation type is 'POP' (indicating a backward navigation, such as using the browser's back button),
 * the animation direction is inverted by negating the offsetX value.
 * This creates a visual effect where the content appears to slide in from the opposite direction,
 * enhancing the user experience by providing a contextual animation based on the user's navigation behavior.
 */
export const SmartSlideAnimationForRouting: React.FC<AnimatedSwipeWrapperProps> = (props) => {
  const { offsetX = '24ch', offsetY = 0 } = props;

  const navigationType = useNavigationType();

  return (
    <SlideFade
      offsetX={navigationType === 'POP' ? `-${offsetX}` : offsetX}
      offsetY={offsetY}
      in={true}
      transition={{ enter: { duration: 0.3 } }}
    >
      {props.children}
    </SlideFade>
  );
};
