import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { Scenes, sitemap } from '../sitemap';
import { API, APIUtils, getHTTPCodeFromTRPCError } from '../../api/trpc';
import { RouterErrorHandler } from '../error/RouterErrorHandler';
import { createFirmwareViewerLoader } from '../../components/Backoffice/Firmware/loaders';
import { createHardwareViewerLoader } from '../../components/Backoffice/Hardware/loaders';
import { AdminTemplate, FullAuthTemplate, SmallAuthTemplate } from '../../components/Layout/Template';
import { createVariableViewerLoader } from '../../components/Backoffice/Variables/loaders';
import { cultivationRecordTypeViewerLoader } from '../../scenes/Backoffice/ContentManager/CultivationRecordType/loaders';
import { createIdentifiersViewerLoader } from '../../components/Backoffice/Identifiers/loaders';
import { useSessionUser } from '../../session/useSessionUser';
import { RootAuthFallbackElement } from './FallbackElement';
import { createLotViewerLoader } from '../../components/Backoffice/Stock/Lot/loaders';
import { createMainboardViewerLoader } from '../../components/Backoffice/Stock/Mainboard/loaders';
import { NavigatePendingDevice, NavigteWithQueryParams } from '../utils';
import { TRPCClientError } from '@trpc/client';
import { createMyCultivationZoneListLoader, createMyCultivationZoneViewerLoader } from '../../scenes/MyCultivation/loaders';
import { createVarietyViewerLoader } from '../../scenes/Lab/Varieties/loaders';
import { createCycleViewerLoader } from '../../scenes/Lab/Cycles/loaders';
import { createPlanListLoader, createPlanViewerLoader } from '../../scenes/Backoffice/ContentManager/Plans/loaders';
import { createSubscriptionViewerLoader } from '../../scenes/Organization/loaders';
import { Header } from '../../components/Layout/Header';
import { Flex, Spacer } from '@chakra-ui/react';
import { Footer } from '../../components/Layout/Footer';
import { createSupportIssueViewerLoader } from '../../scenes/Support/loaders';
import { createAutomationViewerLoader } from '../../scenes/Automations/loaders';
import { createUserViewerLoader } from '../../scenes/Organization/Staff/loaders';
import { createChangeLogViewerLoader } from '../../scenes/Backoffice/ContentManager/ChangeLog/loaders';
import { createCostumerSubscriptionViewerLoader, createCostumerViewerLoader } from '../../scenes/Backoffice/Costumers/loaders';
import { createOverviewMetricsLoader, createSupportMetricsLoader } from '../../scenes/Backoffice/Metrics/loaders';

const authAppLoader = (utils: APIUtils, id: number) => async () => {
  try {
    const data = await utils.organizations.get.ensureData({ id });

    return data;
  } catch (e) {
    if (e instanceof TRPCClientError) {
      const status = getHTTPCodeFromTRPCError(e);
      if (status) {
        throw new Response(e.message, { status });
      }
    }

    throw e;
  }
};

export const AuthRouter: React.FC = () => {
  const user = useSessionUser();
  const defaultOrganization = Number(user.defaultOrganization);

  const utils = API.useUtils();
  const router = createBrowserRouter([
    {
      path: '/',
      loader: authAppLoader(utils, defaultOrganization),
      errorElement: (
        <Flex direction="column" bg={'appBg'} height={'100dvh'} position={'relative'}>
          <Header isSidebarVisible />
          <RouterErrorHandler />
          <Spacer />
          <Footer width={'full'} zIndex={'docked'} mt={2} />
        </Flex>
      ),
      children: [
        {
          path: sitemap[Scenes.BACKOFFICE].path,
          element: <AdminTemplate />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Backoffice/Index'),
            },
            {
              path: sitemap[Scenes.BACKOFFICE].children.contentManager.path,
              children: [
                {
                  index: true,
                  lazy: () => import('../../scenes/Backoffice/ContentManager/Index'),
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.cultivationRecordType.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/ContentManager/CultivationRecordType/Index'),
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.cultivationRecordType.children.viewer.dynamicPath,
                      loader: cultivationRecordTypeViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/CultivationRecordType/Viewer'),
                    },
                    {
                      path: '*',
                      element: (
                        <NavigteWithQueryParams
                          to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.cultivationRecordType.path}
                        />
                      ),
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.firmware.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Firmware/Index'),
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.firmware.children.viewer.dynamicPath,
                      loader: createFirmwareViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Firmware/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.firmware.path} />,
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.hardware.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Hardware/Index'),
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.hardware.children.viewer.dynamicPath,
                      loader: createHardwareViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Hardware/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.hardware.path} />,
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.variable.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Variable/Index'),
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.variable.children.viewer.dynamicPath,
                      loader: createVariableViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Variable/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.variable.path} />,
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.identifiers.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Identifiers/Index'),
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.identifiers.children.viewer.dynamicPath,
                      loader: createIdentifiersViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Identifiers/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.identifiers.path} />,
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.plans.path,
                  children: [
                    {
                      index: true,
                      loader: createPlanListLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Plans/Index'),
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.plans.children.viewer.dynamicPath,
                      loader: createPlanViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/Plans/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.plans.path} />,
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.changeLog.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/ContentManager/ChangeLog/Index'),
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.contentManager.children.changeLog.children.viewer.dynamicPath,
                      loader: createChangeLogViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/ContentManager/ChangeLog/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.contentManager.children.changeLog.path} />,
                    },
                  ],
                },
              ],
            },
            {
              path: sitemap[Scenes.BACKOFFICE].children.costumers.path,
              children: [
                {
                  index: true,
                  lazy: () => import('../../scenes/Backoffice/Costumers/Index'),
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.costumers.children.viewer.dynamicPath,
                  loader: createCostumerViewerLoader(utils),
                  lazy: () => import('../../scenes/Backoffice/Costumers/Viewer'),
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.costumers.children.subscription.dynamicPath,
                  loader: createCostumerSubscriptionViewerLoader(utils),
                  lazy: () => import('../../scenes/Backoffice/Costumers/Subscription'),
                },
                {
                  path: '*',
                  element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.costumers.path} />,
                },
              ],
            },
            {
              path: sitemap[Scenes.BACKOFFICE].children.metrics.path,
              children: [
                {
                  index: true,
                  lazy: () => import('../../scenes/Backoffice/Metrics/Index'),
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.metrics.children.overview.path,
                  loader: createOverviewMetricsLoader(utils),
                  lazy: () => import('../../scenes/Backoffice/Metrics/Overview'),
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.metrics.children.support.path,
                  loader: createSupportMetricsLoader(utils),
                  lazy: () => import('../../scenes/Backoffice/Metrics/Support'),
                },

                {
                  path: '*',
                  element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.metrics.path} />,
                },
              ],
            },
            {
              path: sitemap[Scenes.BACKOFFICE].children.stockManager.path,
              children: [
                {
                  index: true,
                  lazy: () => import('../../scenes/Backoffice/StockManager/Index'),
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.stockManager.children.lot.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/StockManager/Lot/Index'),
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.stockManager.children.lot.children.viewer.dynamicPath,
                      loader: createLotViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/StockManager/Lot/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.stockManager.children.lot.path} />,
                    },
                  ],
                },
                {
                  path: sitemap[Scenes.BACKOFFICE].children.stockManager.children.mainboard.path,
                  children: [
                    {
                      index: true,
                      lazy: () => import('../../scenes/Backoffice/StockManager/Mainboard/Index'),
                    },
                    {
                      path: sitemap[Scenes.BACKOFFICE].children.stockManager.children.mainboard.children.viewer.dynamicPath,
                      loader: createMainboardViewerLoader(utils),
                      lazy: () => import('../../scenes/Backoffice/StockManager/Mainboard/Viewer'),
                    },
                    {
                      path: '*',
                      element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.stockManager.children.mainboard.path} />,
                    },
                  ],
                },
              ],
            },

            {
              path: sitemap[Scenes.BACKOFFICE].children.tools.path,
              children: [
                {
                  index: true,
                  lazy: () => import('../../scenes/Backoffice/Tools/Index'),
                },
                {
                  path: '*',
                  element: <NavigteWithQueryParams to={sitemap[Scenes.BACKOFFICE].children.tools.path} />,
                },
              ],
            },
          ],
        },
        {
          path: sitemap[Scenes.AUTOMATIONS].path,
          element: <FullAuthTemplate />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Automations/Index'),
            },
            {
              path: sitemap[Scenes.AUTOMATIONS].children.advanced.path,
              lazy: () => import('../../scenes/Automations/Advanced'),
            },
            {
              path: sitemap[Scenes.AUTOMATIONS].children.creator.path,
              lazy: () => import('../../scenes/Automations/Creator'),
            },
            {
              path: sitemap[Scenes.AUTOMATIONS].children.viewer.dynamicPath,
              lazy: () => import('../../scenes/Automations/Viewer'),
              loader: createAutomationViewerLoader(utils),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.AUTOMATIONS].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.CULTIVATION_LOGS].path,
          element: <FullAuthTemplate />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/CultivationLogs/Index'),
            },
            {
              path: sitemap[Scenes.CULTIVATION_LOGS].children.viewer.dynamicPath,
              lazy: () => import('../../scenes/CultivationLogs/Viewer'),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.CULTIVATION_LOGS].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.DEVICES].path,
          element: <FullAuthTemplate />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Devices/Index'),
            },
            {
              path: sitemap[Scenes.DEVICES].children.devices.dynamicPath,
              lazy: () => import('../../scenes/Devices/Device'),
            },
            {
              path: sitemap[Scenes.DEVICES].children.modules.dynamicPath,
              lazy: () => import('../../scenes/Devices/Module'),
            },
            {
              path: sitemap[Scenes.DEVICES].children.ios.dynamicPath,
              lazy: () => import('../../scenes/Devices/IO'),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.DEVICES].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.LAB].path,
          element: <FullAuthTemplate />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Lab/Index'),
            },
            {
              path: sitemap[Scenes.LAB].children.cycle.path,
              children: [
                {
                  index: true,
                  lazy: () => import('../../scenes/Lab/Cycles/Index'),
                },
                {
                  path: sitemap[Scenes.LAB].children.cycle.children.viewer.dynamicPath,
                  lazy: () => import('../../scenes/Lab/Cycles/Viewer'),
                  loader: createCycleViewerLoader(utils),
                },
                {
                  path: '*',
                  element: <NavigteWithQueryParams to={sitemap[Scenes.LAB].children.cycle.path} />,
                },
              ],
            },
            {
              path: sitemap[Scenes.LAB].children.variety.path,
              children: [
                {
                  index: true,
                  lazy: () => import('../../scenes/Lab/Varieties/Index'),
                },
                {
                  path: sitemap[Scenes.LAB].children.variety.children.viewer.dynamicPath,
                  lazy: () => import('../../scenes/Lab/Varieties/Viewer'),
                  loader: createVarietyViewerLoader(utils),
                },
                {
                  path: '*',
                  element: <NavigteWithQueryParams to={sitemap[Scenes.LAB].children.variety.path} />,
                },
              ],
            },
            {
              path: sitemap[Scenes.LAB].children.analytics.path,

              children: [
                {
                  index: true,
                  lazy: () => import('../../scenes/Lab/Analytics/Index'),
                },
                {
                  path: '*',
                  element: <NavigteWithQueryParams to={sitemap[Scenes.LAB].children.analytics.path} />,
                },
              ],
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.LAB].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.MY_CULTIVATION].path,
          element: <FullAuthTemplate />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/MyCultivation/Index'),
              loader: createMyCultivationZoneListLoader(utils),
            },
            {
              path: sitemap[Scenes.MY_CULTIVATION].children.zone.dynamicPath,
              lazy: () => import('../../scenes/MyCultivation/Zone'),
              loader: createMyCultivationZoneViewerLoader(utils),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.MY_CULTIVATION].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.ORGANIZATION].path,
          element: <FullAuthTemplate />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Organization/Index'),
            },
            {
              path: sitemap[Scenes.ORGANIZATION].children.subscription.path,
              lazy: () => import('../../scenes/Organization/Subscription'),
              loader: createSubscriptionViewerLoader(utils),
            },
            {
              path: sitemap[Scenes.ORGANIZATION].children.staff.path,
              children: [
                {
                  index: true,
                  lazy: () => import('../../scenes/Organization/Staff/Index'),
                },
                {
                  path: sitemap[Scenes.ORGANIZATION].children.staff.children.user.dynamicPath,
                  lazy: () => import('../../scenes/Organization/Staff/UserProfile'),
                  loader: createUserViewerLoader(utils),
                },
              ],
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.ORGANIZATION].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.PROFILE].path,
          element: <SmallAuthTemplate />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Profile/Index'),
            },
            {
              path: sitemap[Scenes.PROFILE].children.emailVerification.path,
              lazy: () => import('../../scenes/Profile/AttributeVerification'),
            },
            {
              path: sitemap[Scenes.PROFILE].children.notifications.path,
              lazy: () => import('../../scenes/Profile/Notifications'),
            },
            {
              path: sitemap[Scenes.PROFILE].children.phoneVerification.path,
              lazy: () => import('../../scenes/Profile/AttributeVerification'),
            },
            {
              path: sitemap[Scenes.PROFILE].children.preferences.path,
              lazy: () => import('../../scenes/Profile/Preferences'),
            },
            {
              path: sitemap[Scenes.PROFILE].children.security.path,
              lazy: () => import('../../scenes/Profile/Security'),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.PROFILE].path} />,
            },
          ],
        },
        {
          path: sitemap[Scenes.SUPPORT].path,
          element: <FullAuthTemplate />,
          children: [
            {
              index: true,
              lazy: () => import('../../scenes/Support/Index'),
            },
            {
              path: sitemap[Scenes.SUPPORT].children.issue.dynamicPath,
              lazy: () => import('../../scenes/Support/Issue'),
              loader: createSupportIssueViewerLoader(utils),
            },
            {
              path: '*',
              element: <NavigteWithQueryParams to={sitemap[Scenes.SUPPORT].path} />,
            },
          ],
        },
        {
          index: true,
          element: <Navigate to={sitemap[Scenes.MY_CULTIVATION].path} />,
        },
      ],
    },
    {
      path: '*',
      element: <NavigatePendingDevice defaultRoute={sitemap[Scenes.MY_CULTIVATION].path} />,
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<RootAuthFallbackElement />} />;
};
