import { MercadoPagoSubscriptionPlanStatus, MercadoPagoSubscriptionStatus, StripeSubscriptionStatus, SubscriptionProvider } from 'common';
import { IconType } from 'react-icons';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { SiMercadopago as MercadoPagoIcon } from 'react-icons/si';
import { FaCcStripe as StripeIcon } from 'react-icons/fa';

export const commonSubscriptionMessages = defineMessages({
  dataAgeRestriction: {
    id: 'subscriptions.data_age_restriction',
    defaultMessage: 'Historial de datos',
  },
  maxUsersRestriction: {
    id: 'subscriptions.max_users_restriction',
    defaultMessage: 'Número de usuarios',
  },
  planSubscriptionDescription: {
    id: 'subscriptions.plan_subscription_description',
    defaultMessage: 'Obtene acceso a nuestras mejores funcionalidades',
  },
  subscribe: {
    id: 'subscriptions.subscribe',
    defaultMessage: 'Suscribirse',
  },
  subscription: {
    id: 'subscriptions.plan',
    defaultMessage: 'Suscripción',
  },
  subscriptionCantBeReactivated: {
    id: 'subscriptions.subscription_cant_be_reactivated',
    defaultMessage: 'La suscripción no puede ser reactivada',
  },
  trialDays: {
    id: 'subscriptions.trial_days',
    defaultMessage: 'Días de prueba',
  },
  upgradeToProTitle: {
    id: 'subscriptions.upgrade_to_pro_title',
    defaultMessage: 'Activá el modo PRO',
  },
  alreadySubscribedError: {
    id: 'SubscribeToPlan.already_subscribed_error',
    defaultMessage: 'Ya tienes una suscripción',
  },
  planNotFoundError: {
    id: 'SubscribeToPlan.plan_not_found_error',
    defaultMessage: 'Suscripción no encontrada',
  },
  seePlans: {
    id: 'subscriptions.see_plans',
    defaultMessage: 'Ver planes',
  },
  cancelSubscription: {
    id: 'CancelSubscriptionMenuItem.cancel_subscription',
    defaultMessage: 'Cancelar suscripción',
  },
  cancelationWarning: {
    id: 'CancelSubscriptionMenuItem.cancelation_warning',
    defaultMessage: 'Estás a punto de cancelar tu suscripción, esta acción no se puede deshacer. ¿Estás seguro que deseas continuar?',
  },
  nextPayment: {
    id: 'SubscriptionView.next_payment',
    defaultMessage: 'Próximo pago',
  },
  nextPaymentTemplate: {
    id: 'SubscriptionView.next_payment_value',
    defaultMessage: '{amount} el {date}',
  },
  plan: {
    id: 'SubscriptionView.plan',
    defaultMessage: 'Plan',
  },
  since: {
    id: 'SubscriptionView.since',
    defaultMessage: 'Suscripto desde',
  },
  accountMoney: {
    id: 'PaymentMethod.account_money',
    defaultMessage: 'Dinero en cuenta',
  },
  paymentMethod: {
    id: 'PaymentMethod.payment_method',
    defaultMessage: 'Metodo de pago',
  },
});

export const mercadoPagoSubscriptionStatusMessages = defineMessages<MercadoPagoSubscriptionStatus, MessageDescriptor>({
  [MercadoPagoSubscriptionStatus.AUTHORIZED]: {
    id: 'subscriptionsStatusMessages.authorized',
    defaultMessage: 'Autorizada',
  },
  [MercadoPagoSubscriptionStatus.PENDING]: {
    id: 'subscriptionsStatusMessages.pending',
    defaultMessage: 'Pendiente',
  },
  [MercadoPagoSubscriptionStatus.PAUSED]: {
    id: 'subscriptionsStatusMessages.paused',
    defaultMessage: 'Pausada',
  },
  [MercadoPagoSubscriptionStatus.CANCELED]: {
    id: 'subscriptionsStatusMessages.canceled',
    defaultMessage: 'Cancelada',
  },
});

export const mercadoPagoSubscriptionPlanStatusMessages = defineMessages<MercadoPagoSubscriptionPlanStatus, MessageDescriptor>({
  [MercadoPagoSubscriptionPlanStatus.ACTIVE]: {
    id: 'subscriptionsStatusMessages.active',
    defaultMessage: 'Activa',
  },
  [MercadoPagoSubscriptionPlanStatus.CANCELED]: {
    id: 'subscriptionsStatusMessages.canceled',
    defaultMessage: 'Cancelada',
  },
});

export const stripeSubscriptionStatusMessages = defineMessages<StripeSubscriptionStatus, MessageDescriptor>({
  [StripeSubscriptionStatus.INCOMPLETE]: {
    id: 'subscriptionsStatusMessages.incomplete',
    defaultMessage: 'Incompleta',
  },
  [StripeSubscriptionStatus.TRIALING]: {
    id: 'subscriptionsStatusMessages.trialing',
    defaultMessage: 'Prueba',
  },
  [StripeSubscriptionStatus.ACTIVE]: {
    id: 'subscriptionsStatusMessages.active',
    defaultMessage: 'Activa',
  },
  [StripeSubscriptionStatus.INCOMPLPETE_EXPIRED]: {
    id: 'subscriptionsStatusMessages.incomplete_expired',
    defaultMessage: 'Incompleta expirada',
  },
  [StripeSubscriptionStatus.PAST_DUE]: {
    id: 'subscriptionsStatusMessages.past_due',
    defaultMessage: 'Vencida',
  },
  [StripeSubscriptionStatus.CANCELED]: {
    id: 'subscriptionsStatusMessages.canceled',
    defaultMessage: 'Cancelada',
  },
  [StripeSubscriptionStatus.UNPAID]: {
    id: 'subscriptionsStatusMessages.unpaid',
    defaultMessage: 'Sin pagar',
  },
  [StripeSubscriptionStatus.PAUSED]: {
    id: 'subscriptionsStatusMessages.paused',
    defaultMessage: 'Pausada',
  },
});

const providerDescription = defineMessages({
  mercadoPagoDescription: {
    id: 'subscriptions.mercadopago_description',
    defaultMessage: 'Ideal para usuarios de Argentina',
  },
  stripeDescription: {
    id: 'subscriptions.stripe_description',
    defaultMessage: 'Ideal para usuarios en el resto del mundo',
  },
});

export const providerDescriptionMessage: Record<SubscriptionProvider, MessageDescriptor> = {
  [SubscriptionProvider.MERCADO_PAGO]: providerDescription.mercadoPagoDescription,
  [SubscriptionProvider.STRIPE]: providerDescription.stripeDescription,
};

export const providerIcon: Record<SubscriptionProvider, IconType> = {
  [SubscriptionProvider.MERCADO_PAGO]: MercadoPagoIcon,
  [SubscriptionProvider.STRIPE]: StripeIcon,
};
