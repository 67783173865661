export const themeConfig = {
  useSystemColorMode: true,
  initialColorMode: 'system',
  fonts: {
    body: 'Aeonik-Regular, system-ui, sans-serif',
    heading: 'Aeonik-Bold, system-ui, serif',
  },
  colors: {
    brand: {
      50: '#E8C9A8',
      100: '#E1BA8F',
      //200: '#C78035',
      200: '#D59D61', // Changed to the color of 300
      300: '#D59D61',
      400: '#D0904D',
      500: '#C78035',
      600: '#B0712F',
      700: '#996329',
      800: '#825422',
      900: '#815322',
    },
    dark: {
      50: '#4C5854',
      100: '#46504D',
      200: '#3F4946',
      300: '#39413F',
      400: '#333A38',
      500: '#2C3331',
      600: '#262C2A',
      700: '#202423',
      800: '#191D1C',
      900: '#131615',
    },
    light: {
      50: '#A1A696',
      100: '#AAAD9F',
      200: '#B1B4A7',
      300: '#B9BBAF',
      400: '#C0C2B7',
      500: '#C7C9C0',
      600: '#CCCEC5',
      700: '#D3D5CD',
      800: '#DBDCD6',
      900: '#e2e3de',
    },
    pink: {
      50: '#F3D1DE',
      100: '#F0C6D6',
      200: '#ECBBCD',
      300: '#E9AFC5',
      400: '#E6A4BD',
      500: '#E398B4',
      600: '#E08DAC',
      700: '#CA7F9B',
      800: '#B3718A',
      900: '#9D6378',
    },
    indigo: {
      50: '#b5bfed',
      100: '#a5a9e6',
      200: '#969de0',
      300: '#8789da',
      400: '#7878d4',
      500: '#6868cd',
      600: '#5757c7',
      700: '#4646c1',
      800: '#3535ba',
      900: '#2424b3',
    },
  },

  semanticTokens: {
    colors: {
      bg100: {
        default: 'light.100',
        _dark: 'dark.100',
      },
      bg200: {
        default: 'light.200',
        _dark: 'dark.200',
      },
      bg300: {
        default: 'light.300',
        _dark: 'dark.300',
      },
      bg400: {
        default: 'light.400',
        _dark: 'dark.400',
      },
      bg500: {
        default: 'light.500',
        _dark: 'dark.500',
      },
      bg600: {
        default: 'light.600',
        _dark: 'dark.600',
      },
      bg700: {
        default: 'light.700',
        _dark: 'dark.700',
      },
      bg800: {
        default: 'light.800',
        _dark: 'dark.800',
      },
      appBg: {
        default: 'light.800',
        _dark: 'dark.900',
      },
      appBgAlpha: {
        default: 'rgba(219, 220, 214, 0.6)',
        _dark: 'rgba(19, 20, 21, 0.6)',
      },
      fontColor: {
        default: 'dark.200',
        _dark: 'light.700',
      },
      fontColorLight: {
        default: 'dark.100',
        _dark: 'light.400',
      },
      borderColor: {
        default: 'light.200',
        _dark: 'dark.100',
      },
      borderColorLight: {
        default: 'light.400',
        _dark: 'dark.300',
      },
      filledInputLabelBackground: {
        default: 'gray.100',
        _dark: 'whiteAlpha.50',
      },
      error: {
        default: 'red.600',
        _dark: 'red.200',
      },
      success: {
        default: 'green.600',
        _dark: 'green.200',
      },
      brandIcon: {
        default: 'brand.500',
        _dark: 'brand.600',
      },
      brand: {
        default: 'brand.500',
        _dark: 'brand.600',
      },
      brandHover: {
        default: 'brand.100',
        _dark: 'brand.700',
      },
      text: {
        default: 'dark.800',
        _dark: 'light.800',
      },
      negativeText: {
        default: 'light.800',
        _dark: 'dark.800',
      },
      border: {
        default: 'light.200',
        _dark: 'dark.500',
      },
      header: {
        default: 'light.300',
        _dark: 'dark.200',
      },
      headerHover: {
        default: 'light.400',
        _dark: 'dark.500',
      },
      container100: {
        default: 'light.500',
        _dark: 'dark.100',
      },
      container200: {
        default: 'light.800',
        _dark: 'dark.50',
      },
      containerHover: {
        default: 'gray.200',
        _dark: 'whiteAlpha.100',
      },
      brandGradient: {
        default: 'linear-gradient(-45deg, #B0712F, #C78035, #C78035,  #D59D61 )',
        _dark: 'linear-gradient(-45deg, #D0904D, #E4B084, #E4B084, #E8C9A8)',
      },
      brandGradientHover: {
        default: '#C78035',
        _dark: '#E8C9A8',
      },
      inputFocus: {
        default: 'brand.400',
        _dark: 'brand.100',
      },
      inputBackground: {
        default: 'light.900',
        _dark: 'dark.400',
      },
      buttonFontColor: {
        default: 'light.900',
        _dark: 'dark.800',
      },
      cardBg: {
        default: 'light.600',
        _dark: 'dark.600',
      },
      cardBgDisabled: {
        default: 'light.100',
        _dark: 'whiteAlpha.100',
      },
      notificationBackground: {
        default: 'whiteAlpha.500',
        _dark: 'whiteAlpha.200',
      },
      modalBg: {
        default: 'light.500',
        _dark: 'dark.500',
      },
      panelColor: {
        default: 'light.400',
        _dark: 'dark.400',
      },
      warning: {
        default: 'yellow.500',
        _dark: 'yellow.400',
      },
      rangeAlarm: {
        default: '#2e81f4',
        _dark: '#2e81f4',
      },
      thresholdAlarm: {
        default: '#1eff00',
        _dark: '#1eff00',
      },
      administratorRole: {
        default: 'brand.100',
        _dark: 'brand.900',
      },
      growerRole: {
        default: 'pink.200',
        _dark: 'pink.900',
      },
      operatorRole: {
        default: 'indigo.50',
        _dark: 'indigo.900',
      },
      translatorRole: {
        default: 'gray.200',
        _dark: 'gray.900',
      },
      subscriptionGradient: {
        default: 'linear-gradient(-42deg, #00a3c4, #48a2b4, #48a2b4, #70b7c5)',
        _dark: 'linear-gradient(-42deg, #5bb0bd, #6cb8c4, #91dfec, #9decf9)',
      },
      subscriptionHover: {
        default: '#00a3c4',
        _dark: '#9decf9',
      },
    },
  },
  styles: {
    global: () => ({
      'html, body': {
        color: 'fontColor',
        bg: 'appBg',
        borderColor: 'borderColor',
        overscrollBehaviorY: 'none',
      },
      //Webkit styles for input form autofill
      'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
        WebkitBoxShadow: '0 0 0 1000px var(--chakra-colors-bg100) inset !important',
        boxShadow: '0 0 0 1000px var(--chakra-colors-bg100) inset !important',
        WebkitTextFillColor: 'var(--chakra-colors-text) !important',
        transition: 'background-color 5000s ease-in-out 0s !important',
      },
    }),
  },
  components: {
    Card: {
      baseStyle: {
        container: {
          backgroundColor: 'cardBg',
          boxShadow: 'md',
          borderRadius: 'xl',
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          backgroundColor: 'modalBg',
          boxShadow: 'dark-lg',
          borderRadius: 'xl',
        },
        overlay: {
          backgroundColor: 'blackAlpha.300',
          backdropFilter: 'blur(10px) grayscale(.5)',
        },
      },
    },
    Button: {
      variants: {
        gradient: {
          background: 'brandGradient',
          color: 'buttonFontColor',
          transition: 'opacity 0.3s ease-in-out',
          opacity: '1',
          _hover: {
            background: 'brandGradientHover',
            opacity: '1',
            _disabled: {
              opacity: '0.4',
              background: 'brandGradientHover',
            },
          },
          _disabled: {
            opacity: '0.4',
          },
        },
        subscriptionGradient: {
          background: 'subscriptionGradient',
          color: 'buttonFontColor',
          transition: 'opacity 0.3s ease-in-out',
          opacity: '1',
          _hover: {
            background: 'subscriptionHover',
            opacity: '1',
            _disabled: {
              opacity: '0.4',
              background: 'subscriptionHover',
            },
          },
          _disabled: {
            opacity: '0.4',
          },
        },
      },
    },
    Alert: {
      variants: {
        subscriptionGradient: {
          container: {
            background: 'subscriptionGradient',
            color: 'buttonFontColor',
          },
        },
      },
    },
    Badge: {
      variants: {
        subscriptionGradient: {
          background: 'subscriptionGradient',
          color: 'buttonFontColor',
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          color: 'text',
          zIndex: 'dropdown',
          background: 'bg700',
          borderColor: 'borderColor',
          boxShadow: 'lg',
        },
        item: {
          background: 'bg700',
          display: 'flex',
          alignItems: 'center',
          padding: '0.375rem 1rem',
          _hover: {
            background: 'bg800',
          },
          _active: {
            background: 'bg800',
          },
        },
      },
    },

    // floating form labels with/without addon/elements
    Form: {
      variants: {
        withAddon: {
          container: {
            _focusWithin: {
              label: {
                transform: 'scale(0.7) translateY(-36px)',
                borderColor: 'inputFocus',
              },
            },
            input: {
              placeholder: {
                color: 'transparent',
              },
              _focusWithin: {
                borderColor: 'inputFocus',
              },
            },
            // this keeps the label floating when the input is not empty
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, .chakra-numberinput__field:not(:placeholder-shown)  + label, textarea:not(:placeholder-shown) ~ label':
              {
                transform: 'scale(0.7) translateY(-36px)',
              },

            label: {
              left: '3rem',
              color: 'fontColorLight',
              top: 0,
              borderRadius: 'md',
              zIndex: 2,
              position: 'absolute',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
        withElement: {
          container: {
            input: {
              _placeholder: {
                color: 'transparent',
              },
              _focusWithin: {
                borderColor: 'inputFocus',
              },
            },
            _focusWithin: {
              label: {
                transform: 'scale(0.7) translateY(-36px)',
              },
            },
            // this keeps the label floating when the input is not empty
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, .chakra-numberinput__field:not(:placeholder-shown)  + label, textarea:not(:placeholder-shown) ~ label':
              {
                transform: 'scale(0.7) translateY(-36px)',
              },

            label: {
              color: 'fontColorLight',
              left: '1.6rem',
              top: 0,
              borderRadius: 'md',
              zIndex: 2,
              position: 'absolute',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
        floating: {
          'input:placeholder-shown ~ label': {
            transform: 'none',
          },
          container: {
            _focusWithin: {
              label: {
                transform: 'scale(0.7) translateY(-36px)',
              },
              input: {
                shadows: 'none',
              },
            },

            select: {
              _placeholder: {
                color: 'fontColorLight',
              },
            },
            input: {
              _placeholder: {
                color: 'transparent',
              },
              _focusWithin: {
                borderColor: 'inputFocus',
              },
            },
            textarea: {
              _placeholder: {
                color: 'transparent',
              },
              _focusWithin: {
                borderColor: 'inputFocus',
              },
            },
            // this keeps the label floating when the input is not empty
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, .chakra-numberinput__field:not(:placeholder-shown) + label, textarea:not(:placeholder-shown) ~ label':
              {
                transform: 'scale(0.7) translateY(-36px)',
              },

            label: {
              left: 0,
              color: 'fontColorLight',
              top: 0,
              borderRadius: 'md',
              zIndex: 2,
              position: 'absolute',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
      },
    },
  },
};

export enum ColorMode {
  SYSTEM = 'system',
  DARK = 'dark',
  LIGHT = 'light',
}

export const isValidColorMode = (value: string): value is ColorMode => {
  return Object.values(ColorMode).includes(value as ColorMode);
};
